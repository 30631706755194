
import Vue from "vue";
import DatePicker from "@/components/form-components/DatePicker.vue";
import store from "@/store";
import CreateBeneficiary from "@/modules/orders/components/CreateBeneficiary.vue";
import ClientData from "@/modules/orders/components/ClientData.vue";
import rules from "@/services/helpers/validation-rules";
import { mask } from "vue-the-mask";
import Payments from "@/modules/orders/components/Payments.vue";
import allowedFields from "../../../config/available-fields.json";
import FileUploader from "@/components/form-components/FileUploader.vue";
import Allocation from "@/modules/orders/components/Allocation.vue";
import CommonActions from "@/modules/orders/mixins/common_actions";
import Client from "@/modules/orders/mixins/client";
import Allocations from "@/modules/orders/mixins/allocations";
import Comments from "@/modules/orders/mixins/comments";
import Permissions from "@/modules/orders/mixins/permissions";
import DocumentType from "@/modules/orders/mixins/documentType";
import LiveSearch from "@/components/form-components/LiveSearch.vue";
import certGoods from "@/modules/orders/mixins/certGoods";
import MultipleLiveSearch from "@/components/form-components/MultipleLiveSearch.vue";
import DocumentEdit from "@/modules/orders/components/order_types/CERT/DocumentEdit.vue";
import CertificationRequest from "@/modules/orders/components/order_types/CERT/CertificationRequest.vue";
import stockConfig from "../../../config/stock-config.json";
import Services from "@/modules/orders/components/Services.vue";
import ExpertReport from "@/modules/orders/components/dialogs/ExpertReport.vue";

export default Vue.extend({
  name: "AtaCreateOrder",

  components: {
    Services,
    DocumentEdit,
    MultipleLiveSearch,
    LiveSearch,
    Allocation,
    Payments,
    DatePicker,
    CreateBeneficiary,
    ClientData,
    FileUploader,
    CertificationRequest,
    ExpertReport
  },

  mixins: [
    CommonActions,
    Client,
    Allocations,
    Comments,
    Permissions,
    DocumentType,
    certGoods
  ],

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    },
    currentStatusAlias: {
      type: String,
      required: true,
      default: ""
    }
  },

  directives: { mask },

  data: () => ({
    stockConfig: stockConfig as any,
    allowedFields: allowedFields as any,
    rules,
    selectedField: "" as string,
    loading: false as boolean,
    loadingPayments: false as boolean,
    loadingCarnetIssused: false as boolean,
    selectedClient: null as null | SelectComponentInterface,
    selectedCard: null as null | SelectComponentInterface,
    model: {
      parent_documents: [] as Array<any>,
      documents: [] as Array<any>,
      order: {
        subdivision_id: 1,
        branch_id: 1,
        source: "operator",
        client_delegate: "",
        client: { type: "" } as any,
        document_valid_from: new Date().toISOString().substr(0, 10),
        power_of_attorney: "beneficiary" as string
      } as any,
      details: {
        goods: [] as Array<any>,
        services: []
      } as any
    } as any,
    errorMessages: {} as any,
    selects: {
      cardTypes: [] as Array<SelectComponentInterface>,
      purpose: [] as Array<SelectComponentInterface>,
      packingCategories: [] as Array<SelectComponentInterface>,
      languages: [] as Array<SelectComponentInterface>,
      clientTypes: [] as Array<SelectComponentInterface>,
      release_types: [] as Array<SelectComponentInterface>,
      currencies: ["agro", "other"] as Array<string>
    } as any,
    lang: store.getters["localization/getCurrent"],
    selectedTab: 1,
    totalSum: 0 as number,
    createBeneficiaryModal: false as boolean,
    isShowDocuments: true as boolean,
    isShowDocumentsNumber: false as boolean,
    isLoading: false,
    breadcrumb: [
      {
        text: "orders.title",
        disabled: false,
        href: "/orders"
      },
      {
        text: "orders.form.header.create",
        disabled: true,
        href: `/orders/edit`
      }
    ],
    defaultCountry: null as any,
    comments: {} as any,
    oldModel: {} as any,
    forceKey: 0 as number,
    regions: [
      { text: "Moldova", value: "moldova" },
      { text: "Transnistria", value: "transnistria" }
    ],
    powerOfAttorney: null as any,
    advice: null as any,
    panel: [0] as Array<number>,
    maxLength: 12,
    maxDocumentCounter: 20,
    paymentsTotalPrice: 0 as number,
    // changeStatusDialog: false as boolean,
    dialog: false as boolean
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        this.model = this.value;

        if (this.model.hasOwnProperty("additions")) {
          if (this.model.additions.hasOwnProperty("notes")) {
            this.selectedTab = 1;
            this.comments = this.parseComments(this.model.additions.notes);
          }
        }

        if (this.model.hasOwnProperty("parent_documents")) {
          if (!this.isMultipleSelect) {
            const [item] = this.model.parent_documents;
            if (item) {
              this.selectedCard = { ...item };
            }
          }
        }

        if (
          this.model.documents.some((item: any) => item.file_type === "advice")
        ) {
          this.advice = this.model.documents.find(
            (item: any) => item.file_type === "advice"
          ).file;
        }
      }
    },
    "model.documents": {
      immediate: true,
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    },
    errorMessages() {
      Object.keys(this.errorMessages)
        .filter((key: string) => key.startsWith("details.goods."))
        .map(this.getErrorIndex);
    },

    "model.details.goods": {
      immediate: true,
      deep: true,
      handler() {
        this.panel.push(this.model.details.goods.length);
      }
    }
  },

  computed: {
    isDataChanged() {
      if (this.model && this.oldModel) {
        return JSON.stringify(this.model) !== JSON.stringify(this.oldModel);
      }
      return false;
    },
    currencyText() {
      return this.currency?.text || "";
    },
    formattedDate() {
      return this.$moment(this.model.order.document_valid_from, "YYYY-MM-DD")
        .add(1, "years")
        .subtract(1, "days")
        .format("DD.MM.YYYY");
    },
    goodsSum() {
      return this.model.details.goods.reduce(
        (
          total: number,
          { price, quantity }: { price: number; quantity: number }
        ) => {
          if (price && quantity) {
            return total + price * quantity;
          }
          return total;
        },
        0
      );
    },
    currency() {
      return this.selects.currencies.find(
        (item: any) => item.value === this.model.details.currency_id
      );
    },
    isMultipleSelect() {
      return this.model.order.document_type === "substitution";
    },
    canEditForm() {
      return this.model.order.status.alias !== "issued";
    },
    computedRules(): object {
      return {
        goodsMassValidation: this.goodsMassValidation
      };
    },
    headers() {
      return this.stockConfig.headers.map((item: any) => ({
        ...item,
        text: this.$t(item.text)
      }));
    },
    statusChanged() {
      return this.currentStatusAlias !== this.model.order.status.alias;
    },
    currentRole() {
      return this.$store.getters["user/currentRole"];
    },
    isSpecialist() {
      return this.currentRole.alias === "specialist";
    },
    isBookkeeper() {
      return (
        this.currentRole.alias === "bookkeeper" ||
        this.currentRole.alias === "bookkeeper_chief"
      );
    },
    printCertificateLabel() {
      if (this.currentStatusAlias === "issued") {
        return this.$t("orders.buttons.cert.preview_certificate_issued");
      }
      return this.$t("orders.buttons.cert.preview_certificate");
    },
    isIssued() {
      return this.currentStatusAlias === "issued";
    },
    consigneeLabel() {
      if (this.isSpecialist) {
        return this.$t("orders.form.fields.recipient_specialist");
      }
      return this.$t("orders.form.fields.recipient");
    }
  },

  async mounted() {
    await this.loadData();
    await this.setCurrentDate();
  },

  methods: {
    setPaymentsPrice(price: number) {
      this.paymentsTotalPrice = price;
    },
    goodsMassValidation(index: number): any {
      if (!this.model.details.goods[index].massa_netto) {
        return true;
      }

      return (
        Number(this.model.details.goods[index].massa_netto) <=
          Number(this.model.details.goods[index].massa_brutto) ||
        this.$t("orders.form.fields.net_mass_error_message")
      );
    },
    openPDF(url: string) {
      window.open(url, "_blank");
    },
    async downloadCertification(): Promise<void> {
      // window.open(
      //   `${process.env.VUE_APP_API_SERVER_URL}/orders/${this.$route.params.id}/order_document_print`
      // );
      const response = await this.$API
        .orders()
        .getPreviewCert(Number(this.$route.params.id));
      window.open(response.url);
    },
    setDefaultTab() {
      if (
        this.model.order.status &&
        ["processing", "issued", "canceled"].includes(
          this.model.order.status.alias
        )
      ) {
        this.selectedTab = 1;
        return;
      } else {
        this.selectedTab = 3;
      }
    },
    getOrderSum(ev: any) {
      this.totalSum = ev;
    },
    async getData() {
      this.$emit("update");
    },
    onTabChange(tabIndex: number): void {
      this.loadingPayments = tabIndex === 2;
      this.loadingCarnetIssused = tabIndex === 3;
    },

    async loadLists(): Promise<void> {
      try {
        const headers = {
          "x-localization": this.model.order.language
        };

        const [purpose, { items }, countries] = await Promise.all([
          this.$API
            .purposeOfUse()
            .getList(this.model.order.sertice_type_id, headers)
        ]);

        this.selects = {
          ...this.selects,
          purpose
        };
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },

    async loadData() {
      this.loading = true;
      try {
        const headers = {
          "x-localization": this.model.order.language
        };

        const response = await Promise.all([
          this.$API
            .purposeOfUse()
            .getList(this.model.order.sertice_type_id, headers),
          this.$API.orders().getOrderReleaseModes(),
          this.$API.clients().getClientTypes(),
          this.$API.orders().getCardTypes(this.model.order.service_type_id),
          this.$API.orders().getLanguages(),
          this.$API.transportTypes().getList(headers),
          this.$API.employees().getList(
            {
              filter: { subdivisions: this.model.order.subdivision_id }
            },
            headers
          ),
          this.$API.goodsCategories().getList(),
          this.$API.countries().getList(headers),
          this.$API
            .measureUnits()
            .getList(
              { service_type_id: this.model.order.service_type_id },
              headers
            ),
          this.$API.packingCategories().getList(headers),
          this.$API.currency().getList(),
          this.$API.measureUnits().getList(
            {
              service_type_id: this.model.order.service_type_id,
              is_weight_unit: true
            },
            headers
          )
        ]);

        await this.setServerResponse(response);
      } catch (e) {
        await store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    parseModel(model: any): any {
      const goods = [] as Array<any>;
      model.details.reports.forEach((item: any) => {
        if (item.hasOwnProperty("goods")) {
          item.goods.forEach((el: any) => {
            goods.push(el);
          });
        } else {
          goods.push(item);
        }
      });
      model.details.reports = [...goods];

      if (
        model.hasOwnProperty("parent_documents") &&
        Array.isArray(model.parent_documents)
      ) {
        model.parent_documents = model.parent_documents.map((item: any) => {
          if (item.hasOwnProperty("value")) {
            return item.value;
          }

          if (item.hasOwnProperty("id")) {
            return item.id;
          }

          return item;
        });
      }

      return model;
    },
    async submit(): Promise<void> {
      this.isLoading = true;
      const form = this.$refs.form as Vue;

      try {
        if (form.validate()) {
          this.model.documents = this.model.documents.filter(
            (item: any) => item.file_type !== "confirm_represent_power"
          );

          if (this.powerOfAttorney.file) {
            this.model.documents.push(this.powerOfAttorney);
          }

          const model = JSON.parse(JSON.stringify(this.model));

          await this.$API
            .orders()
            .edit(Number(this.$route.params.id), { ...this.parseModel(model) });

          form.updateGuard();
          // this.changeStatusDialog = !this.statusChanged;

          // if (!this.changeStatusDialog) {
          //   await this.$emit("update");
          // }
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        } else {
          this.selectedTab = 1;
          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);
        }
        this.$emit("update");
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          if (e.errors.length) {
            this.selectedTab = 1;
          }

          await setTimeout(() => {
            const firstErrorInput = form.inputs.find(
              (item: any) => item.messagesToDisplay.length
            );

            if (firstErrorInput) {
              this.$vuetify.goTo(firstErrorInput.$el, {
                offset: 150
              });
            }
          }, 500);

          setTimeout(() => {
            this.errorMessages = {};
          }, 4000);
        }
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoading = false;
    },
    async setServerResponse(response: Array<any>) {
      const [
        purpose,
        releaseModes,
        clientTypes,
        cardTypes,
        languages,
        transportTypes,
        employees,
        goodsCategories,
        countries,
        units,
        packingCategories,
        currencies,
        weightUnits
      ] = response;

      this.selects.languages = languages.items;
      this.selects.purpose = purpose;
      this.selects.release_types = releaseModes.items;
      this.selects.clientTypes = clientTypes;
      this.selects.cardTypes = cardTypes.items;
      this.selects.transportTypes = transportTypes.items;
      this.selects.employees = employees.items;
      this.selects.goodsCategories = goodsCategories;
      this.selects.countries = countries.items;
      this.selects.units = units;
      this.selects.packingCategories = packingCategories;
      this.selects.currencies = currencies.items;
      this.selects.weightUnits = weightUnits;

      this.$nextTick(() => {
        this.oldModel = JSON.parse(JSON.stringify(this.model));
        this.$forceUpdate();
      });
    },
    changeData(items: Array<any>) {
      this.model.details.countries = items;
    },
    setBeneficiary(event: any) {
      this.selectedClient = { value: event.id, text: event.name };
      this.setClient(event);
    },
    loadDocuments(val: any) {
      this.model.documents = val;
    },
    async setExchangeRate(currency: SelectComponentInterface): Promise<void> {
      if (!currency.value) {
        return;
      }

      if (currency.text === "MDL") {
        this.model.details.exchange_rate = "1.0000";
        return;
      }

      try {
        const { value } = await this.$API
          .exchangeRates()
          .getExchangeRate(Number(currency.value));

        this.model.details.exchange_rate = value;
        this.$forceUpdate();
        this.forceKey++;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    addDocument(): void {
      this.model.documents.push({ file_type: "certification" });
    },
    removeDocument(index: number): void {
      this.model.documents.splice(index, 1);
    },
    reload(): void {
      this.$emit("update");
    },
    toggleView(): void {
      this.$router.push(`/orders/preview/${this.$route.params.id}`);
    },
    setStock(event: Array<any>): void {
      this.model.details.goods = event;
    },
    closeConfirm() {
      // this.changeStatusDialog = false;
      this.$emit("update");
    },
    setCurrentDate() {
      this.model.details.order_date = new Date().toISOString().substr(0, 10);
    }
  }
});
