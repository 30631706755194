
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import NestedSelect from "@/components/form-components/NestedSelect.vue";
import login from "@/modules/auth/views/login.vue";

export default Vue.extend({
  name: "CertificationRequest",

  components: {
    NestedSelect
  },

  props: {
    headers: {
      type: Array,
      required: false
    },
    errorMessages: {
      type: Object,
      required: false,
      default: () => ({})
    },
    language: {
      type: String,
      required: true,
      default: "ru"
    },
    items: {
      type: Array,
      required: false
    },
    serviceId: {
      type: Number,
      required: true
    },
    value: {
      type: Array,
      default: [] as Array<any>
    },
    clientId: {
      type: Number,
      required: true
    },
    branchId: {
      type: Number,
      required: true
    }
  },

  data: () => ({
    rules,
    localItems: [] as Array<any>,
    selects: {
      categories: [] as Array<SelectComponentInterface>,
      expertises: [] as Array<SelectComponentInterface>,
      units: [] as Array<SelectComponentInterface>
    } as any,
    panel: [] as Array<number>,
    subcategories: {} as any,
    forceKey: 0 as number,
    toCancel: false as any,
    selectedProduct: null as any,
    model: [] as Array<any>,
    selectedExpertise: null as any,
    showExpertises: false as boolean
  }),

  computed: {
    filteredItems() {
      if (!this.selectedExpertise) {
        return [];
      }
      return this.selects.expertises.filter(this.filterExpertises);
    }
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.model = this.value;
        const goods = [] as Array<any>;
        this.value.forEach((item: any) => {
          if (!goods.some((el: any) => el.exp_number === item.exp_number)) {
            if (item.hasOwnProperty("goods")) {
              goods.push(item);
              return;
            }
            goods.push({
              exp_number: item.exp_number,
              exp_id: item.exp_id,
              goods: this.value.filter((el: any) => el.exp_id === item.exp_id)
            });
          }
        });

        this.model = [
          ...goods.map((item: any) => {
            item.goods.forEach((el: any) => {
              if (!el.quantity || !el.quantity_cert) {
                el.difference = 0;
                return;
              }
              el.difference = el.quantity - el.quantity_cert;
            });
            return item;
          })
        ];

        this.loadChildren();
      }
    },
    items: {
      immediate: true,
      handler() {
        if (!Array.isArray(this.items)) {
          this.localItems = [];
        } else {
          this.localItems = this.items;
        }
      }
    }
  },

  async mounted() {
    await this.loadData();
    await this.loadChildren();
  },

  methods: {
    filterExpertises(expertise: any): any {
      return (
        expertise.goods?.some(
          (item: any) =>
            item?.code
              ?.toLowerCase()
              .includes(this.selectedExpertise.toLowerCase()) ||
            item?.name
              ?.toLowerCase()
              .includes(this.selectedExpertise.toLowerCase())
        ) ||
        expertise.number
          ?.toLowerCase()
          .includes(this.selectedExpertise.toLowerCase())
      );
    },
    viewExpertise(orderId: number): void {
      window.open(this.$router.resolve(`/orders/preview/${orderId}`).href);
    },
    async loadChildren(): Promise<void> {
      try {
        // for (const item of this.model) {
        //   for (const good of item.goods) {
        //     await this.loadChildrenList(good.category_id);
        //   }
        // }
        for (const item of this.localItems) {
          await this.loadChildrenList(item.category_id);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadData(): Promise<void> {
      try {
        const [categories, units, expertises] = await Promise.all([
          this.$API.goodsCategories().getParentsList(),
          this.$API.measureUnits().getList({ service_type_id: 5 }),
          this.$API
            .clients()
            .getExpertises(this.clientId, { branch_id: this.branchId })
        ]);

        this.selects = { categories, units, expertises };
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
    },
    async deleteRow(index: number): Promise<void> {
      this.model.splice(index, 1);
      this.$emit("input", this.model);
    },
    async loadChildrenList(id: number): Promise<void> {
      try {
        if (!id) {
          return;
        }

        if (this.subcategories[id]) {
          return;
        }

        this.subcategories[
          id
        ] = await this.$API.goodsCategories().getChildrenList(id);
        this.forceRender();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
    },
    forceRender(): void {
      this.forceKey = this.forceKey++;
    },
    addItem(): void {
      this.localItems.push({
        category_id: null,
        subcategory_id: null,
        code: "",
        name: "",
        quantity: "",
        measure_unit_id: null
      });
    },
    async submit(): Promise<void> {
      const form = this.$refs.form as Vue;
      if (form.validate()) {
        this.$emit("submit");

        if (this.toCancel) {
          await this.$router.push("/orders");
        }
      }
    },
    async search(value: string, key: string): Promise<any> {
      try {
        this.$forceUpdate();
        return await (this.$API as any)[key]().liveSearchCode(
          {
            search_field: "name",
            search_value: value,
            service_type_id: this.serviceId
          },
          { "x-localization": this.language }
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.forceRender();
    },
    async searchByCode(value: string, field: string): Promise<any> {
      try {
        this.$forceUpdate();
        return await this.$API.goods().liveSearchCode(
          {
            search_field: field,
            search_value: value,
            service_type_id: this.serviceId
          },
          { "x-localization": this.language }
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.forceRender();
    },
    setCode(event: any, index: number) {
      if (!this.localItems[index].code) {
        this.localItems[index].code = event.code;
      }
      this.localItems[index].name = event.name;
    },
    setName(event: any, index: number) {
      this.localItems[index].code = event.code;
      this.localItems[index].name = event.name;
      console.log(this.localItems);
    },
    quantityDifference(reportIndex: number, index: number) {
      if (
        !this.model[reportIndex].goods[index].quantity ||
        !this.model[reportIndex].goods[index].quantity_cert
      ) {
        this.model[reportIndex].goods[index].difference = 0;
        return;
      }
      this.model[reportIndex].goods[index].difference =
        this.model[reportIndex].goods[index].quantity -
        this.model[reportIndex].goods[index].quantity_cert;
      this.$emit("input", this.model);
    },
    changeData() {
      const goods = [] as Array<any>;
      this.model.forEach((item: any) => {
        if (item.hasOwnProperty("goods")) {
          item.goods.forEach((el: any) => {
            goods.push(el);
          });
        } else {
          goods.push(item);
        }
      });
      return JSON.parse(JSON.stringify(goods));
    },
    async addExpertise(item: any, index: number) {
      if (!item.goods.length) {
        await this.$store.dispatch(
          "alert/showWarning",
          this.$t("orders.modals.alert.no_goods_for_expertise")
        );
        return;
      }

      if (!this.model.some((el: any) => el.exp_number === item.number)) {
        await this.loadChildren();
        this.model.push({
          exp_number: item.number,
          exp_id: item.id,
          goods: [...item.goods.map((el: any) => ({ ...el, exp_id: item.id }))]
        });

        this.showExpertises = false;
        this.$emit("input", this.model);
        this.$nextTick(() => {
          this.selectedExpertise = null;
          this.$forceUpdate();
        });

        this.selects.expertises.splice(index, 1);
      }
    },
    toggleShowExpertise() {
      this.showExpertises = !this.showExpertises;
    },
    setSelectedExpertise(event: any): void {
      this.selectedExpertise = event;
    },
    openOrder(orderId: number): void {
      window.open(
        `${process.env.VUE_APP_API_SERVER_URL}/orders/${orderId}/pdf/expert_report`
      );
    }
  }
});
